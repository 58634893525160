import { Kanjis, KanjiRandomizer } from './kanji-randomizer.interface';
import { IndexRandomizer } from '../kanagame/level';
import { Kanji } from '../kanji';

export class NonRepetitiveKanjiRandomizer implements KanjiRandomizer {
  private readonly usedIndices = new Map<number, boolean>();

  constructor(private readonly sortedKanjis: Kanjis, private readonly indexRandomizer: IndexRandomizer) {}

  next(): Kanji {
    if (this.usedIndices.size === this.sortedKanjis.length) {
      this.usedIndices.clear();
    }
    let index = this.indexRandomizer.next(this.sortedKanjis.length);
    while (this.usedIndices.get(index)) {
      index = this.indexRandomizer.next(this.sortedKanjis.length);
    }
    this.usedIndices.set(index, true);
    return this.sortedKanjis[index];
  }
}
