import React, { ReactNode } from 'react';
import { useState } from 'react';
import { Container, createMuiTheme, Tabs, Tab } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { yellow } from '@material-ui/core/colors';
import { KanaGame } from './KanaGame';
import { Compose } from './Compose';
import { KanjiGame } from './KanjiGame';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: yellow[100],
    },
  },
  overrides: {
    MuiCardContent: {
      root: {
        paddingTop: '4px',
        paddingBottom: '4px',
        '&:last-child': {
          paddingBottom: '4px',
        },
      },
    },
    MuiCardActions: {
      root: {
        paddingTop: '2px',
        paddingBottom: '2px',
      },
    },
  },
});

type ModeTabProps = {
  index: number;
  value: number;
  children: ReactNode;
};
function ModeTab(props: ModeTabProps) {
  if (props.index !== props.value) {
    return <></>;
  }
  return <>{props.children}</>;
}

function App() {
  const [tab, setTab] = useState(0);

  function changeTab(event: any, newTab: number) {
    setTab(newTab);
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xs">
        <Tabs value={tab} onChange={changeTab} indicatorColor="primary" textColor="primary" centered style={{ height: '57px', marginBottom: '3px' }}>
          <Tab label="Kana Game" value={0} />
          <Tab label="Kanji Game" value={2} />
          <Tab label="Compose" value={1} />
        </Tabs>
        <ModeTab index={0} value={tab}>
          <KanaGame />
        </ModeTab>
        <ModeTab index={1} value={tab}>
          <Compose />
        </ModeTab>
        <ModeTab index={2} value={tab}>
          <KanjiGame />
        </ModeTab>
      </Container>
    </ThemeProvider>
  );
}

export default App;
