import React, { useState } from 'react';
import { KanaCell, KanaCellInfos } from './KanaCell';
import { Hiragana, Kana, Katakana } from './model/kana';
import { EmptyCell } from './EmptyCell';
import { Tabs, Tab } from '@material-ui/core';

export interface KanaContainer {
  contains(kana: Kana): boolean;
  isKanaMarkedRight(kana: Kana): boolean | undefined;
  showKana(kana: Kana): boolean;
}

export type KanaTableProps = {
  level: KanaContainer;
  isOver: boolean;
  tryKana: (kana: Kana) => void;
  kanaEmphasis?: boolean;
  smallKana?: boolean;
};

enum KanaTab {
  HIRAGANA,
  KATAKANA,
}

type KanasProps = {
  createInfos: (kana: Kana) => KanaCellInfos;
  handleClick: (kana: Kana) => void;
  tab: KanaTab;
  smallKana?: boolean;
};

function HiraganaTable(props: KanasProps) {
  const { handleClick, createInfos } = props;

  if (props.tab !== KanaTab.HIRAGANA) {
    return <></>;
  }

  return (
    <>
      <table>
        <tbody>
          <tr>
            <KanaCell infos={createInfos(Hiragana.N)} onClick={handleClick} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.A)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.I)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.E)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.U)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.O)} />
          </tr>
          <tr>
            <KanaCell infos={createInfos(Hiragana.VU)} onClick={handleClick} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.KA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.KI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.KE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.KU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.KO)} />
          </tr>
          <tr>
            {props.smallKana ? <KanaCell onClick={handleClick} infos={createInfos(Hiragana.SMALL_YA)} /> : <EmptyCell span={1} />}
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.GA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.GI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.GE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.GU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.GO)} />
          </tr>
          <tr>
            {props.smallKana ? <KanaCell onClick={handleClick} infos={createInfos(Hiragana.SMALL_YU)} /> : <EmptyCell span={1} />}
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.SA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.SI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.SE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.SU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.SO)} />
          </tr>
          <tr>
            {props.smallKana ? <KanaCell onClick={handleClick} infos={createInfos(Hiragana.SMALL_YO)} /> : <EmptyCell span={1} />}
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.ZA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.ZI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.ZE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.ZU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.ZO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.DA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.DI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.DE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.DU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.DO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.TA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.TI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.TE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.TU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.TO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.NA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.NI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.NE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.NU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.NO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.HA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.HI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.HE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.HU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.HO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.BA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.BI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.BE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.BU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.BO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.PA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.PI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.PE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.PU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.PO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.MA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.MI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.ME)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.MU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.MO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.YA)} />
            <EmptyCell span={2} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.YU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.YO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.RA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.RI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.RE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.RU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.RO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.WA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.WI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.WE)} />
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Hiragana.WO)} />
          </tr>
        </tbody>
      </table>
    </>
  );
}

function KatakanaTable(props: KanasProps) {
  const { handleClick, createInfos } = props;

  if (props.tab !== KanaTab.KATAKANA) {
    return <></>;
  }

  return (
    <>
      <table>
        <tbody>
          <tr>
            <KanaCell infos={createInfos(Katakana.N)} onClick={handleClick} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.A)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.I)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.E)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.U)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.O)} />
          </tr>
          <tr>
            <KanaCell infos={createInfos(Katakana.VU)} onClick={handleClick} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.KA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.KI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.KE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.KU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.KO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.GA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.GI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.GE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.GU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.GO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.SA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.SI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.SE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.SU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.SO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.ZA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.ZI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.ZE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.ZU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.ZO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.DA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.DI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.DE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.DU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.DO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.TA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.TI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.TE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.TU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.TO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.NA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.NI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.NE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.NU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.NO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.HA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.HI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.HE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.HU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.HO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.BA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.BI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.BE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.BU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.BO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.PA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.PI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.PE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.PU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.PO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.MA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.MI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.ME)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.MU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.MO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.YA)} />
            <EmptyCell span={2} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.YU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.YO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.RA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.RI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.RE)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.RU)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.RO)} />
          </tr>
          <tr>
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.WA)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.WI)} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.WE)} />
            <EmptyCell span={1} />
            <KanaCell onClick={handleClick} infos={createInfos(Katakana.WO)} />
          </tr>
        </tbody>
      </table>
    </>
  );
}

export function KanaTable(props: KanaTableProps) {
  const [tab, setTab] = useState(KanaTab.HIRAGANA);

  function handleClick(kana: Kana): void {
    if (props.isOver) {
      return;
    }
    props.tryKana(kana);
  }
  function createInfos(kana: Kana): KanaCellInfos {
    return {
      kana,
      shown: props.level.contains(kana),
      mark: props.level.isKanaMarkedRight(kana),
      showHiragana: props.level.showKana(kana),
      kanaEmphasis: props.kanaEmphasis,
    };
  }

  const changeTab = (event: any, newValue: KanaTab) => {
    setTab(newValue);
  };

  return (
    <>
      <Tabs value={tab} onChange={changeTab} indicatorColor="primary" textColor="primary" centered>
        <Tab label="ひらがな" value={KanaTab.HIRAGANA} />
        <Tab label="カタカナ" value={KanaTab.KATAKANA} />
      </Tabs>
      <div
        style={{
          maxHeight: 'calc(100vh - 190px)',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <HiraganaTable tab={tab} handleClick={handleClick} createInfos={createInfos} smallKana={props.smallKana} />
        <KatakanaTable tab={tab} handleClick={handleClick} createInfos={createInfos} />
      </div>
    </>
  );
}
