import React, { useState, useEffect } from 'react';
import { Snackbar, SnackbarContent, Slide } from '@material-ui/core';
import { CheckCircle, ErrorRounded, RefreshRounded } from '@material-ui/icons';

import { KanaTable } from './KanaTable';
import { Kana } from './model/kana';
import { snackbarStyles } from './KanaGame';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { KanjiGuessProposition } from './KanjiGuessProposition';
import { KanaString } from './model/kana-string';
import { NonRepetitiveKanjiRandomizer } from './model/kanjigame/kanji-randomizer';
import { joyoKanjis } from './model/kanjigame/kanji-difficulty';
import { MathRandomIndexRandomizer } from './model/math-random-index-randomizer';

const kanjiRandomizer = new NonRepetitiveKanjiRandomizer(joyoKanjis, new MathRandomIndexRandomizer());

let kanji = kanjiRandomizer.next();

export const KanjiGame = () => {
  const snackClasses = snackbarStyles();
  const [kanas, setKanas] = useState(new KanaString());
  const [levelOver, setLevelOver] = useState(false);
  const [clickable, setClickable] = useState(true);
  const [openRight, setOpenRight] = useState(false);
  const [openWrong, setOpenWrong] = useState(false);
  const [openNextLevel, setOpenNextLevel] = useState(false);
  const [currentKanji, setCurrentKanji] = useState(kanji);

  const composeLevel = {
    contains: (kana: Kana): boolean => true,
    isKanaMarkedRight: (kana: Kana): boolean | undefined => undefined,
    showKana: (kana: Kana): boolean => true,
  };

  const changeLevel = (event: React.SyntheticEvent<any>, reason: string) => {
    if (!levelOver) {
      return;
    }
    setOpenNextLevel(false);
    setClickable(true);
    reset();
    kanji = kanjiRandomizer.next();
    setCurrentKanji(kanji);
    setLevelOver(false);
  };

  const handleClose = (event: React.SyntheticEvent<any>, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dismissAll();
    setLevelOver(true);
    setOpenNextLevel(true);
  };

  function dismissAll() {
    setOpenRight(false);
    setOpenWrong(false);
  }

  function handleGuess(k: Kana) {
    setKanas(kanas.append(k));
  }

  useEffect(() => {
    if (kanas.matches(currentKanji.kanas)) {
      setClickable(false);
      setOpenRight(true);
    }
  }, [kanas, currentKanji]);

  function SlideTransition(props: TransitionProps) {
    return <Slide unmountOnExit {...props} direction="up" />;
  }

  function reset() {
    setKanas(kanas.reset());
  }

  function backspace() {
    setKanas(kanas.backspace());
  }

  function giveup() {
    setClickable(false);
    setOpenWrong(true);
  }

  function formatAnswer() {
    return `${currentKanji.kanji} / ${currentKanji.kanas.map(k => k.hiragana).join('')} (${currentKanji.meaning})`;
  }

  return (
    <>
      <KanjiGuessProposition kanji={currentKanji} kanas={kanas.map(k => k)} reset={reset} backspace={backspace} giveup={giveup} />
      <KanaTable level={composeLevel} tryKana={handleGuess} isOver={!clickable} kanaEmphasis={true} smallKana={true} />
      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={openRight} autoHideDuration={800} onClose={handleClose}>
        <SnackbarContent
          className={snackClasses.success}
          message={
            <span className={snackClasses.message}>
              <CheckCircle />
              &nbsp; Right: {formatAnswer()}
            </span>
          }
        />
      </Snackbar>
      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={openWrong} autoHideDuration={2000} onClose={handleClose}>
        <SnackbarContent
          className={snackClasses.error}
          message={
            <span className={snackClasses.message}>
              <ErrorRounded />
              &nbsp; Answer: {formatAnswer()}
            </span>
          }
        />
      </Snackbar>
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={openNextLevel}
        autoHideDuration={700}
        transitionDuration={{ enter: 700 }}
        onClose={changeLevel}
        TransitionComponent={SlideTransition}
      >
        <SnackbarContent
          className={snackClasses.primary}
          message={
            <span className={snackClasses.message}>
              <RefreshRounded />
              &nbsp; Loading Next Level...
            </span>
          }
        />
      </Snackbar>
    </>
  );
};
