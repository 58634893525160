import React from 'react';
import { Kana } from './model/kana';
import { Typography, Card, CardContent } from '@material-ui/core';

export type GuessPropositionProps = {
  kana: Kana;
  isOver: boolean;
};

export function KanaGuessProposition(props: GuessPropositionProps) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h4" style={{ textAlign: 'center' }}>
          {props.isOver ? '...' : `${props.kana.hiragana}`}
        </Typography>
      </CardContent>
    </Card>
  );
}
