import { Kana, Hiragana } from './kana';

export class Kanji {
  constructor(readonly kanji: string, readonly meaning: string, readonly kanas: Kana[]) {}
}

export const JoyoKanji = {
  //Numerals
  ICHI_ONE: new Kanji('\u4E00', 'one', [Hiragana.I, Hiragana.TI]),
  NI_TWO: new Kanji('\u4E8C', 'two', [Hiragana.NI]),
  SAN_THREE: new Kanji('\u4E09', 'three', [Hiragana.SA, Hiragana.N]),
  YON_FOUR: new Kanji('\u56DB', 'four', [Hiragana.YO, Hiragana.N]),
  GO_FIVE: new Kanji('\u4E94', 'five', [Hiragana.GO]),
  ROKU_SIX: new Kanji('\u516D', 'six', [Hiragana.RO, Hiragana.KU]),
  NANA_SEVEN: new Kanji('\u4E03', 'seven', [Hiragana.NA, Hiragana.NA]),
  HACHI_EIGHT: new Kanji('\u516B', 'eight', [Hiragana.HA, Hiragana.TI]),
  KYU_NINE: new Kanji('\u4E5D', 'nine', [Hiragana.KI, Hiragana.SMALL_YU, Hiragana.U]),
  JU_TEN: new Kanji('\u5341', 'ten', [Hiragana.ZI, Hiragana.SMALL_YU, Hiragana.U]),
  HYAKU_HUNDRED: new Kanji('\u767E', 'hundred', [Hiragana.HI, Hiragana.SMALL_YA, Hiragana.KU]),
  SEN_THOUSAND: new Kanji('\u5343', 'thousand', [Hiragana.SE, Hiragana.N]),
  MAN_TEN_THOUSANDS: new Kanji('\u4E07', 'ten thousands', [Hiragana.MA, Hiragana.N]),
  // Animals
  TORI_BIRD: new Kanji('\u9ce5', 'bird', [Hiragana.TO, Hiragana.RI]),
  NEKO_CAT: new Kanji('\u732B', 'cat', [Hiragana.NE, Hiragana.KO]),
  INU_DOG: new Kanji('\u72AC', 'dog', [Hiragana.I, Hiragana.NU]),
  SAKANA_FISH: new Kanji('\u9B5A', 'fish', [Hiragana.SA, Hiragana.KA, Hiragana.NA]),
};
