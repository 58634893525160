import React from 'react';
import { makeStyles, CSSProperties } from '@material-ui/styles';
import { Theme, Card, CardActionArea, CardContent } from '@material-ui/core';
import { green, red, grey, blueGrey } from '@material-ui/core/colors';

import { Kana } from './model/kana';

const baseCss: CSSProperties = {
  width: '3em',
  minWidth: '3em',
  maxWidth: '3em',
  height: '3em',
  minHeight: '3em',
  maxHeight: '3em',
  textAlign: 'center',
  borderRadius: 9,
  overflow: 'hidden',
  fontSize: '1em',
};

export const kanacellStyles = makeStyles((theme: Theme) => ({
  success: {
    ...baseCss,
    backgroundColor: green[600],
    color: theme.palette.getContrastText(green[600]),
  },
  error: {
    ...baseCss,
    backgroundColor: red[500],
    color: theme.palette.getContrastText(red[500]),
  },
  normal: {
    ...baseCss,
    backgroundColor: blueGrey[500],
  },
  notShown: {
    ...baseCss,
    backgroundColor: grey[800],
  },
  waitingResponse: {
    maxHeight: '1em',
    marginTop: '3px',
    marginBottom: '3px',
    color: grey[700],
  },
  content: {
    color: theme.palette.getContrastText(blueGrey[500]),
    fontFamily: 'Roboto',
    paddingTop: '0px',
    paddingLeft: '0',
    fontSize: '16px',
    textAlign: 'center',
    width: '100%',
  },
  empty: {
    ...baseCss,
    backgroundColor: 'transparent',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export type KanaCellInfos = {
  kana: Kana;
  mark: boolean | undefined;
  shown: boolean;
  showHiragana: boolean;
  kanaEmphasis?: boolean;
};

export type KanaCellProps = {
  infos: KanaCellInfos;
  onClick: (kana: Kana) => void;
};

enum GuessState {
  NORMAL,
  RIGHT,
  WRONG,
}

function markToGuessState(mark: boolean | undefined): GuessState {
  switch (mark) {
    case true:
      return GuessState.RIGHT;
    case false:
      return GuessState.WRONG;
    case undefined:
      return GuessState.NORMAL;
  }
}

const forced = false;

export function KanaCell(props: KanaCellProps) {
  const classes = kanacellStyles();
  const state = markToGuessState(props.infos.mark);

  function handleClick() {
    if (state !== GuessState.NORMAL) {
      return;
    }
    props.onClick(props.infos.kana);
  }

  const kanaClass = props.infos.kanaEmphasis ? '' : classes.waitingResponse;
  const romajiClass = props.infos.kanaEmphasis ? classes.waitingResponse : '';

  if (!forced && !props.infos.shown) {
    return <td className={classes.notShown}>&nbsp;</td>;
  } else if (forced || state !== GuessState.NORMAL) {
    return (
      <td>
        <Card className={state === GuessState.RIGHT ? classes.success : classes.error}>
          <CardContent className={classes.content}>
            <div className={kanaClass}>{props.infos.kana.hiragana}</div>
            <div className={romajiClass}>{props.infos.kana.romanji}</div>
          </CardContent>
        </Card>
      </td>
    );
  }

  return (
    <td>
      <Card className={classes.normal} onClick={handleClick}>
        <CardActionArea>
          <CardContent className={classes.content}>
            <div className={kanaClass}>{props.infos.showHiragana ? props.infos.kana.hiragana : '?'}</div>
            <div className={romajiClass}>{props.infos.kana.romanji}</div>
          </CardContent>
        </CardActionArea>
      </Card>
    </td>
  );
}
