export class Kana {
  constructor(readonly romanji: string, readonly hiragana: string) {}
}
export abstract class Hiragana {
  static N = new Kana('N', '\u3093');
  static A = new Kana('A', '\u3042');
  static I = new Kana('I', '\u3044');
  static U = new Kana('U', '\u3046');
  static E = new Kana('E', '\u3048');
  static O = new Kana('O', '\u304A');

  static KA = new Kana('KA', '\u304B');
  static KI = new Kana('KI', '\u304D');
  static KU = new Kana('KU', '\u304F');
  static KE = new Kana('KE', '\u3051');
  static KO = new Kana('KO', '\u3053');

  static SA = new Kana('SA', '\u3055');
  static SI = new Kana('SHI', '\u3057');
  static SU = new Kana('SU', '\u3059');
  static SE = new Kana('SE', '\u305B');
  static SO = new Kana('SO', '\u305D');

  static TA = new Kana('TA', '\u305F');
  static TI = new Kana('CHI', '\u3061');
  static TU = new Kana('TSU', '\u3064');
  static TE = new Kana('TE', '\u3066');
  static TO = new Kana('TO', '\u3068');

  static NA = new Kana('NA', '\u306A');
  static NI = new Kana('NI', '\u306B');
  static NU = new Kana('NU', '\u306C');
  static NE = new Kana('NE', '\u306D');
  static NO = new Kana('NO', '\u306E');

  static HA = new Kana('HA', '\u306F');
  static HI = new Kana('HI', '\u3072');
  static HU = new Kana('FU', '\u3075');
  static HE = new Kana('HE', '\u3078');
  static HO = new Kana('HO', '\u307B');

  static MA = new Kana('MA', '\u307E');
  static MI = new Kana('MI', '\u307F');
  static MU = new Kana('MU', '\u3080');
  static ME = new Kana('ME', '\u3081');
  static MO = new Kana('MO', '\u3082');

  static YA = new Kana('YA', '\u3084');
  static YU = new Kana('YU', '\u3086');
  static YO = new Kana('YO', '\u3088');

  static RA = new Kana('RA', '\u3089');
  static RI = new Kana('RI', '\u308A');
  static RU = new Kana('RU', '\u308B');
  static RE = new Kana('RE', '\u308C');
  static RO = new Kana('RO', '\u308D');

  static WA = new Kana('WA', '\u308F');
  static WI = new Kana('WI', '\u3090');
  static WE = new Kana('WE', '\u3091');
  static WO = new Kana('WO', '\u3092');

  static GA = new Kana('GA', '\u304C');
  static GI = new Kana('GI', '\u304E');
  static GU = new Kana('GU', '\u3050');
  static GE = new Kana('GE', '\u3052');
  static GO = new Kana('GO', '\u3054');

  static ZA = new Kana('ZA', '\u3056');
  static ZI = new Kana('ZI', '\u3058');
  static ZU = new Kana('ZU', '\u305A');
  static ZE = new Kana('ZE', '\u305C');
  static ZO = new Kana('ZO', '\u305E');

  static DA = new Kana('DA', '\u3060');
  static DI = new Kana('DI', '\u3062');
  static DU = new Kana('DU', '\u3065');
  static DE = new Kana('DE', '\u3067');
  static DO = new Kana('DO', '\u3069');

  static BA = new Kana('BA', '\u3070');
  static BI = new Kana('BI', '\u3073');
  static BU = new Kana('BU', '\u3076');
  static BE = new Kana('BE', '\u3079');
  static BO = new Kana('BO', '\u307C');

  static PA = new Kana('PA', '\u3071');
  static PI = new Kana('PI', '\u3074');
  static PU = new Kana('PU', '\u3077');
  static PE = new Kana('PE', '\u307A');
  static PO = new Kana('PO', '\u307D');

  static VU = new Kana('VU', '\u3094');

  static SMALL_YU = new Kana('yu', '\u3085');
  static SMALL_YA = new Kana('ya', '\u3083');
  static SMALL_YO = new Kana('yo', '\u3087');
}

export abstract class Katakana {
  static N = new Kana('N', '\u30F3');
  static A = new Kana('A', '\u30A2');
  static I = new Kana('I', '\u30A4');
  static U = new Kana('U', '\u30A6');
  static E = new Kana('E', '\u30A8');
  static O = new Kana('O', '\u30AA');

  static KA = new Kana('KA', '\u30AB');
  static KI = new Kana('KI', '\u30AD');
  static KU = new Kana('KU', '\u30AF');
  static KE = new Kana('KE', '\u30B1');
  static KO = new Kana('KO', '\u30B3');

  static SA = new Kana('SA', '\u30B5');
  static SI = new Kana('SHI', '\u30B7');
  static SU = new Kana('SU', '\u30B9');
  static SE = new Kana('SE', '\u30BB');
  static SO = new Kana('SO', '\u30BD');

  static TA = new Kana('TA', '\u30BF');
  static TI = new Kana('CHI', '\u30C1');
  static TU = new Kana('TSU', '\u30C4');
  static TE = new Kana('TE', '\u30C6');
  static TO = new Kana('TO', '\u30C8');

  static NA = new Kana('NA', '\u30CA');
  static NI = new Kana('NI', '\u30CB');
  static NU = new Kana('NU', '\u30CC');
  static NE = new Kana('NE', '\u30CD');
  static NO = new Kana('NO', '\u30CE');

  static HA = new Kana('HA', '\u30CF');
  static HI = new Kana('HI', '\u30D2');
  static HU = new Kana('FU', '\u30D5');
  static HE = new Kana('HE', '\u30D8');
  static HO = new Kana('HO', '\u30DB');

  static MA = new Kana('MA', '\u30DE');
  static MI = new Kana('MI', '\u30DF');
  static MU = new Kana('MU', '\u30E0');
  static ME = new Kana('ME', '\u30E1');
  static MO = new Kana('MO', '\u30E2');

  static YA = new Kana('YA', '\u30E4');
  static YU = new Kana('YU', '\u30E6');
  static YO = new Kana('YO', '\u30E8');

  static RA = new Kana('RA', '\u30E9');
  static RI = new Kana('RI', '\u30EA');
  static RU = new Kana('RU', '\u30EB');
  static RE = new Kana('RE', '\u30EC');
  static RO = new Kana('RO', '\u30ED');

  static WA = new Kana('WA', '\u30EF');
  static WI = new Kana('WI', '\u30F0');
  static WE = new Kana('WE', '\u30F1');
  static WO = new Kana('WO', '\u30F2');

  static GA = new Kana('GA', '\u30AC');
  static GI = new Kana('GI', '\u30AE');
  static GU = new Kana('GU', '\u30B0');
  static GE = new Kana('GE', '\u30B2');
  static GO = new Kana('GO', '\u30B4');

  static ZA = new Kana('ZA', '\u30B6');
  static ZI = new Kana('ZI', '\u30B8');
  static ZU = new Kana('ZU', '\u30BA');
  static ZE = new Kana('ZE', '\u30BC');
  static ZO = new Kana('ZO', '\u30BE');

  static DA = new Kana('DA', '\u30C0');
  static DI = new Kana('DI', '\u30C2');
  static DU = new Kana('DU', '\u30C5');
  static DE = new Kana('DE', '\u30C7');
  static DO = new Kana('DO', '\u30C9');

  static BA = new Kana('BA', '\u30D0');
  static BI = new Kana('BI', '\u30D3');
  static BU = new Kana('BU', '\u30D6');
  static BE = new Kana('BE', '\u30D9');
  static BO = new Kana('BO', '\u30DC');

  static PA = new Kana('PA', '\u30D1');
  static PI = new Kana('PI', '\u30D4');
  static PU = new Kana('PU', '\u30D7');
  static PE = new Kana('PE', '\u30DA');
  static PO = new Kana('PO', '\u30DD');

  static VU = new Kana('VU', '\u30F4');
}
