import React from 'react';
import { kanacellStyles } from './KanaCell';

export type EmptyCellProps = {
  span: number;
};

export function EmptyCell(props: EmptyCellProps) {
  const classes = kanacellStyles();
  return (
    <td
      className={classes.empty}
      colSpan={props.span}
    >
      &nbsp;
    </td>
  );
}
