import { Kana } from './kana';

export class KanaString {
  constructor(private readonly kanas: Kana[] = []) {}

  matches(kanas: Kana[]): boolean {
    return JSON.stringify(this.kanas) === JSON.stringify(kanas);
  }
  append(kana: Kana): KanaString {
    return new KanaString([...this.kanas, kana]);
  }
  reset(): KanaString {
    return new KanaString([]);
  }
  backspace(): KanaString {
    return new KanaString(this.kanas.slice(0, -1));
  }
  map<T>(f: (k: Kana) => T): T[] {
    return this.kanas.map(f);
  }
}
