import React, { useState, useEffect, useRef } from 'react';
import { Kana } from './model/kana';
import { Typography, Card, CardContent, CardActions, IconButton, Icon } from '@material-ui/core';
import { Kanji } from './model/kanji';
import { red } from '@material-ui/core/colors';

export type GuessPropositionProps = {
  kanji: Kanji;
  kanas: Kana[];
  reset: () => void;
  backspace: () => void;
  giveup: () => void;
};

export function KanjiGuessProposition(props: GuessPropositionProps) {
  const [showMeaning, setShowMeaning] = useState(false);
  const soundContainer = useRef<HTMLAudioElement>(null);

  function playsound() {
    if (soundContainer.current) {
      soundContainer.current.play();
    }
  }

  useEffect(() => {
    setShowMeaning(false);
  }, [props.kanji]);

  return (
    <Card>
      <CardContent style={{ minHeight: '80px' }}>
        <Typography
          variant="h6"
          style={{ textAlign: 'center', minHeight: '48px', verticalAlign: 'bottom', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <div style={{ flexBasis: '33%', textAlign: 'right' }}>
            <span style={{ padding: '12px' }}>{props.kanji.kanji}</span>
          </div>
          <div style={{ flexBasis: '33%' }}>
            <IconButton onClick={playsound}>
              <Icon>volume_up</Icon>
            </IconButton>
          </div>
          <div
            style={{
              display: showMeaning ? 'none' : undefined,
              textAlign: 'left',
              flexBasis: '33%',
            }}
          >
            <IconButton onClick={() => setShowMeaning(true)}>
              <Icon>help_outline</Icon>
            </IconButton>
          </div>
          <Typography
            variant="caption"
            style={{
              display: showMeaning ? undefined : 'none',
              textAlign: 'left',
              flexBasis: '33%',
            }}
          >
            <span style={{ padding: '12px' }}>({props.kanji.meaning})</span>
          </Typography>
        </Typography>
        <Typography variant="h6">{props.kanas.map(k => k.hiragana).join('')}</Typography>
      </CardContent>
      <CardActions>
        <IconButton onClick={props.reset}>
          <Icon>delete</Icon>
        </IconButton>
        <IconButton onClick={props.backspace}>
          <Icon>backspace</Icon>
        </IconButton>
        <IconButton onClick={props.giveup} style={{ color: red[500] }}>
          <Icon>skip_next</Icon>
        </IconButton>
      </CardActions>
      <audio ref={soundContainer} src={`${process.env.PUBLIC_URL}/kanji/mp3/${props.kanji.kanji}.mp3`} />
    </Card>
  );
}
