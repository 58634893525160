import { IndexRandomizer } from './kanagame/level';
import { MultiIndexRandomizer } from './kanagame/kana-level-builder';

export class MathRandomIndexRandomizer implements IndexRandomizer {
  next(max: number): number {
    return Math.round(Math.random() * (max - 1));
  }
}

export class MathRandomMultiIndexRandomizer implements MultiIndexRandomizer {
  private readonly randomizer = new MathRandomIndexRandomizer();

  next(count: number, max: number): number[] {
    const present = new Map<number, boolean>();
    const result: number[] = [];
    for (; result.length < count; ) {
      const randomized = this.randomizer.next(max);
      if (!present.get(randomized)) {
        present.set(randomized, true);
        result.push(randomized);
      }
    }
    return result;
  }
}
