import React, { useState } from 'react';

import { KanaTable } from './KanaTable';
import { Kana } from './model/kana';
import { Typography, Card, CardContent, CardActions, IconButton, Icon } from '@material-ui/core';
import { KanaString } from './model/kana-string';

export function Compose() {
  const [kanas, setKanas] = useState(new KanaString());
  const composeLevel = {
    contains: (kana: Kana): boolean => true,
    isKanaMarkedRight: (kana: Kana): boolean | undefined => undefined,
    showKana: (kana: Kana): boolean => true,
  };

  function handleGuess(k: Kana) {
    setKanas(kanas.append(k));
  }

  function reset() {
    setKanas(kanas.reset());
  }

  function backspace() {
    setKanas(kanas.backspace());
  }

  return (
    <>
      <Card>
        <CardContent style={{ minHeight: '53px' }}>
          <Typography variant="h5">{kanas.map(k => k.hiragana).join('')}</Typography>
          <Typography variant="caption">{kanas.map(k => k.romanji).join('')}</Typography>
        </CardContent>
        <CardActions>
          <IconButton onClick={reset}>
            <Icon>delete</Icon>
          </IconButton>
          <IconButton onClick={backspace}>
            <Icon>backspace</Icon>
          </IconButton>
        </CardActions>
      </Card>
      <KanaTable level={composeLevel} tryKana={handleGuess} isOver={false} smallKana={true} />
    </>
  );
}
