import { Level, IndexRandomizer } from './level';
import { Kana } from '../kana';

export interface MultiIndexRandomizer {
  next(count: number, max: number): number[];
}

export type SortedKanas = {
  starter: Kana[];
  allBasics: Kana[];
  withModifiers: Kana[];
};

type Difficulty = keyof SortedKanas;

export class KanaLevelBuilder {
  private selectedKanas: Kana[] = [];
  private count = 2;
  private left: Kana[];
  private difficulty: Difficulty;
  constructor(private readonly randomizer: MultiIndexRandomizer, private readonly sortedKanas: SortedKanas) {
    this.left = sortedKanas.starter;
    this.difficulty = 'starter';
  }

  nextLevel(indexRandomizer: IndexRandomizer): Level {
    const kanas = this.retrieveNextKanas();
    return new Level(kanas, indexRandomizer);
  }

  private changeDifficulty() {
    switch (this.difficulty) {
      case 'starter':
        this.difficulty = 'allBasics';
        this.left.push(...this.sortedKanas.allBasics);
        break;
      case 'allBasics':
        this.difficulty = 'withModifiers';
        this.left.push(...this.sortedKanas.withModifiers);
        break;
    }
  }

  private retrieveNextKanas(): Kana[] {
    const indexes = this.randomizer.next(this.count, this.left.length);
    const kanas = indexes.map(index => this.left[index]);
    if (this.count === this.left.length) {
      this.changeDifficulty();
    }
    this.count = Math.min(this.count + 4, this.left.length);
    return kanas;
  }
}
