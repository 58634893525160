import { Kana } from '../kana';
import { KanaContainer } from '../../KanaTable';

export interface IndexRandomizer {
  next(max: number): number;
}

export class Level implements KanaContainer {
  private current: Kana;
  private left: Kana[];
  private over: boolean = false;
  private guesses: Map<Kana, boolean | undefined> = new Map();
  constructor(kanas: Kana[], private readonly randomizer: IndexRandomizer) {
    this.left = [...kanas];
    this.current = this.next();
    kanas.forEach(kana => this.guesses.set(kana, undefined));
  }

  private next(): Kana {
    if (this.left.length === 0) {
      this.over = true;
      return this.current;
    }
    const index = this.randomizer.next(this.left.length - 1);
    const [current] = this.left.splice(index, 1);
    this.current = current;
    return current;
  }
  isOver(): boolean {
    return this.over;
  }

  currentKana(): Kana {
    return this.current;
  }

  tryGuess(kana: Kana): boolean {
    const rightGuess = kana === this.current;
    this.guesses.set(this.current, rightGuess);
    this.next();
    return rightGuess;
  }
  contains(kana: Kana): boolean {
    return this.guesses.has(kana);
  }
  showKana(kana: Kana): boolean {
    return false;
  }
  isKanaMarkedRight(kana: Kana): boolean | undefined {
    return this.guesses.get(kana);
  }
}
