import { SortedKanas } from './kana-level-builder';
import { Hiragana, Katakana } from '../kana';

export const difficulty: SortedKanas = {
  starter: [Hiragana.N, Hiragana.A, Hiragana.O, Hiragana.I, Hiragana.U, Hiragana.RO, Hiragana.RU, Hiragana.SI, Hiragana.YO],
  allBasics: [
    Hiragana.E,

    Hiragana.RA,
    Hiragana.RE,
    Hiragana.RI,

    Hiragana.KA,
    Hiragana.KE,
    Hiragana.KI,
    Hiragana.KO,
    Hiragana.KU,

    Hiragana.SA,
    Hiragana.SE,
    Hiragana.SO,
    Hiragana.SU,

    Hiragana.TA,
    Hiragana.TE,
    Hiragana.TI,
    Hiragana.TO,
    Hiragana.TU,

    Hiragana.NA,
    Hiragana.NE,
    Hiragana.NI,
    Hiragana.NO,
    Hiragana.NU,

    Hiragana.HA,
    Hiragana.HE,
    Hiragana.HI,
    Hiragana.HO,
    Hiragana.HU,

    Hiragana.MA,
    Hiragana.ME,
    Hiragana.MI,
    Hiragana.MO,
    Hiragana.MU,

    Hiragana.YA,
    Hiragana.YU,

    Hiragana.WA,
    Hiragana.WE,
    Hiragana.WI,
    Hiragana.WO,

    Katakana.N,
    Katakana.A,
    Katakana.O,
    Katakana.I,
    Katakana.U,
    Katakana.E,

    Katakana.RO,
    Katakana.RU,
    Katakana.RA,
    Katakana.RE,
    Katakana.RI,

    Katakana.KA,
    Katakana.KE,
    Katakana.KI,
    Katakana.KO,
    Katakana.KU,

    Katakana.SA,
    Katakana.SE,
    Katakana.SO,
    Katakana.SI,
    Katakana.SU,

    Katakana.TA,
    Katakana.TE,
    Katakana.TI,
    Katakana.TO,
    Katakana.TU,

    Katakana.NA,
    Katakana.NE,
    Katakana.NI,
    Katakana.NO,
    Katakana.NU,

    Katakana.HA,
    Katakana.HE,
    Katakana.HI,
    Katakana.HO,
    Katakana.HU,

    Katakana.MA,
    Katakana.ME,
    Katakana.MI,
    Katakana.MO,
    Katakana.MU,

    Katakana.YO,
    Katakana.YA,
    Katakana.YU,

    Katakana.WA,
    Katakana.WE,
    Katakana.WI,
    Katakana.WO,
  ],
  withModifiers: [
    Hiragana.GA,
    Hiragana.GI,
    Hiragana.GU,
    Hiragana.GE,
    Hiragana.GO,

    Hiragana.ZA,
    Hiragana.ZI,
    Hiragana.ZU,
    Hiragana.ZE,
    Hiragana.ZO,

    Hiragana.DA,
    Hiragana.DI,
    Hiragana.DU,
    Hiragana.DE,
    Hiragana.DO,

    Hiragana.BA,
    Hiragana.BI,
    Hiragana.BU,
    Hiragana.BE,
    Hiragana.BO,

    Hiragana.PA,
    Hiragana.PI,
    Hiragana.PU,
    Hiragana.PE,
    Hiragana.PO,

    Hiragana.VU,

    Katakana.GA,
    Katakana.GI,
    Katakana.GU,
    Katakana.GE,
    Katakana.GO,

    Katakana.ZA,
    Katakana.ZI,
    Katakana.ZU,
    Katakana.ZE,
    Katakana.ZO,

    Katakana.DA,
    Katakana.DI,
    Katakana.DU,
    Katakana.DE,
    Katakana.DO,

    Katakana.BA,
    Katakana.BI,
    Katakana.BU,
    Katakana.BE,
    Katakana.BO,

    Katakana.PA,
    Katakana.PI,
    Katakana.PU,
    Katakana.PE,
    Katakana.PO,

    Katakana.VU,
  ],
};
