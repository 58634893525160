import React, { useState } from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { makeStyles } from '@material-ui/styles';
import { Theme, Slide, Snackbar, SnackbarContent } from '@material-ui/core';
import { green, red, blueGrey } from '@material-ui/core/colors';
import { CheckCircle, ErrorRounded, RefreshRounded } from '@material-ui/icons';

import { KanaLevelBuilder } from './model/kanagame/kana-level-builder';
import { MathRandomMultiIndexRandomizer, MathRandomIndexRandomizer } from './model/math-random-index-randomizer';
import { difficulty } from './model/kanagame/difficulty';
import { Kana } from './model/kana';
import { KanaGuessProposition } from './KanaGuessProposition';
import { KanaTable } from './KanaTable';

const levelBuilder = new KanaLevelBuilder(new MathRandomMultiIndexRandomizer(), difficulty);

const indexRandomizer = new MathRandomIndexRandomizer();

let level = levelBuilder.nextLevel(indexRandomizer);

export const snackbarStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: red[500],
  },
  primary: {
    backgroundColor: blueGrey[700],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const clickEffectDuration = 100;

export function KanaGame() {
  const snackClasses = snackbarStyles();
  const [currentLevel, setCurrentLevel] = useState(level);
  const [currentKana, setCurrentKana] = useState(level.currentKana());
  const [levelOver, setLevelOver] = useState(false);
  const [openRight, setOpenRight] = useState(false);
  const [openWrong, setOpenWrong] = useState(false);
  const [openNextLevel, setOpenNextLevel] = useState(false);

  function handleGuess(kana: Kana) {
    dismissAll();
    setTimeout(() => {
      const right = level.tryGuess(kana);
      if (right) {
        setOpenRight(true);
      } else {
        setOpenWrong(true);
      }

      if (level.isOver()) {
        setOpenNextLevel(true);
        setLevelOver(true);
      } else {
        setCurrentKana(level.currentKana());
      }
    }, clickEffectDuration);
  }

  const handleClose = (event: React.SyntheticEvent<any>, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dismissAll();
  };

  function dismissAll() {
    setOpenRight(false);
    setOpenWrong(false);
  }

  const changeLevel = (event: React.SyntheticEvent<any>, reason: string) => {
    if (reason === 'clickaway' || !level.isOver()) {
      return;
    }
    level = levelBuilder.nextLevel(indexRandomizer);
    setLevelOver(false);
    setOpenNextLevel(false);
    setCurrentLevel(level);
    setCurrentKana(level.currentKana());
  };

  function SlideTransition(props: TransitionProps) {
    return <Slide unmountOnExit {...props} direction="up" />;
  }

  return (
    <>
      <KanaGuessProposition kana={currentKana} isOver={levelOver} />
      <KanaTable level={currentLevel} tryKana={handleGuess} isOver={levelOver} />

      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={openRight} autoHideDuration={800} onClose={handleClose}>
        <SnackbarContent
          className={snackClasses.success}
          message={
            <span className={snackClasses.message}>
              <CheckCircle />
              &nbsp; Right
            </span>
          }
        />
      </Snackbar>
      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={openWrong} autoHideDuration={800} onClose={handleClose}>
        <SnackbarContent
          className={snackClasses.error}
          message={
            <span className={snackClasses.message}>
              <ErrorRounded />
              &nbsp; Wrong
            </span>
          }
        />
      </Snackbar>
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={openNextLevel}
        autoHideDuration={700}
        onClose={changeLevel}
        TransitionComponent={SlideTransition}
      >
        <SnackbarContent
          className={snackClasses.primary}
          message={
            <span className={snackClasses.message}>
              <RefreshRounded />
              &nbsp; Loading Next Level...
            </span>
          }
        />
      </Snackbar>
    </>
  );
}
